export const columnsFromBackend = {
    "list": {
        name: "LISTADO EXCEL",
        title: 'LISTADO EXCEL',
        items: []
    },
    'sku': {
        name: "SKU",
        title: 'SKU',
        items: []
    },
    'familia': {
        name: "FAMILIA",
        title: 'FAMILIA',
        items: []
    },
    'sub_familia': {
        name: "SUBFAMILIA",
        title: 'SUB FAMILIA',
        items: []
    },
    'marca': {
        name: "MARCA",
        title: 'MARCA',
        items: []
    },
    'armadora': {
        name: "ARMADORA",
        title: 'ARMADORA',
        items: []
    },
    'modelo': {
        name: "MODELO",
        title: 'MODELO',
        items: []
    },
    'sub_modelo': {
        name: "SUBMODELO",
        title: 'SUB MODELO',
        items: []
    },
    'posicion': {
        name: "POSICION",
        title: 'POSICIÓN',
        items: []
    },
    'atributo': {
        name: "ATRIBUTOS",
        title: 'ATRIBUTOS',
        items: []
    },
    'inicial': {
        name: "ANIO_INICIAL",
        title: 'AÑO INICIAL',
        items: []
    },
    'final': {
        name: "ANIO_FINAL",
        title: 'AÑO FINAL',
        items: []
    },
    'imagen': {
        name: "IMAGEN",
        title: 'IMAGEN',
        items: []
    },
    'equivalencia_1': {
        name: "EQUIVALENCIA_1",
        title: 'EQUIVALENCIA 1',
        items: []
    },
    'equivalencia_2': {
        name: "EQUIVALENCIA_2",
        title: 'EQUIVALENCIA 2',
        items: []
    },
    'equivalencia_3': {
        name: "EQUIVALENCIA_3",
        title: 'EQUIVALENCIA 3',
        items: []
    },
    'equivalencia_4': {
        name: "EQUIVALENCIA_4",
        title: 'EQUIVALENCIA 4',
        items: []
    },
    'equivalencia_5': {
        name: "EQUIVALENCIA_5",
        title: 'EQUIVALENCIA 5',
        items: []
    },
    'precio': {
        name: "PRECIO_LISTA",
        title: 'PRECIO DE LISTADO',
        items: []
    },
};

export const itemsStepper = [
    {
        label: 'Indicar lectura del  documento',
        command: (event) => {
            // toast.current.show({ severity: 'info', summary: 'First Step', detail: event.item.label });
        }
    },
    {
        label: 'Seleccionar archivo',
        command: (event) => {
            // toast.current.show({ severity: 'info', summary: 'Seat Selection', detail: event.item.label });
        }
    },
    // {
    //   label: 'Formateo',
    //   command: (event) => {
    //     // toast.current.show({ severity: 'info', summary: 'Pay with CC', detail: event.item.label });
    //   }
    // },
    // {
    //     label: 'Confirmation',
    //     command: (event) => {
    //         toast.current.show({ severity: 'info', summary: 'Last Step', detail: event.item.label });
    //     }
    // }
];


export const initialDataForm = {
    sheetName: '',
    selectRowIndex: '',
    selectColumnIndex: '',
    separator: ''
}
