import fileDownload from 'js-file-download';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuid4 } from 'uuid';
import './App.css';
import { columnsFromBackend, itemsStepper } from './helpers/initialData';

function App() {
  const url = process.env.REACT_APP_URL
  const toast = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { watch, register, handleSubmit, setValue, formState: { errors }, getValues, trigger, control } = useForm()
  const [loadingForm, setLoadingForm] = useState(false)
  const [only, setOnly] = useState(false)
  const [columns, setColumns] = useState(columnsFromBackend)

  const handleNext = async (step = 0) => {
    if (step === 0) {
      setActiveIndex(step)
      return
    }
    if (getValues('typeDocument')) {

      const result = await trigger(['sheetName', 'selectRowIndex', 'separator', 'selectColIndex', 'fieldProcess'])
      if (result) {
        setActiveIndex(step)
      }

      return
    }
    const result = await trigger(['sheetName', 'selectRowIndex', 'separator', 'selectColIndex'])
    if (result) {
      setActiveIndex(step)
    }
  }
  const onHandleSubmit = async (data) => {
    try {
      //Convierto el array de columnas en un diccionario
      let dataSend = Object.entries(columns).map(([key, value]) => {
        if (key !== 'list') {
          return {
            [key]: value.items.length > 0 ? value.items[0].content.toLowerCase().trim() : ''
          }
        }
        return undefined

      })

      //Agrupo todos los campos en un solo objeto
      dataSend = dataSend.filter((item) => item !== undefined)
      dataSend = Object.assign({}, ...dataSend)

      //Creo el formulario que voy a enviar
      const form = new FormData()
      form.append('file_data', data.file_data[0])
      form.append('selectColIndex', data.selectColIndex ?? 1)
      form.append('selectRowIndex', data.selectRowIndex ?? 1)
      form.append('typeDocument', data.typeDocument ?? false)
      form.append('sheetName', data.sheetName)
      form.append('fieldProcess',data.fieldProcess ?? 'None' )
      form.append('separator', data.separator ?? ';')
      form.append('separatorColumn', data.separatorColumn ?? ',')
      form.append('diccionary', JSON.stringify(dataSend))

      setLoadingForm(true)
      //Realizo la peticion
      const res = await fetch(`${url}/format_excel`, {
        method: 'POST',
        body: form
      })

      setLoadingForm(false)

      //Realizo la peticion
      if (res.status === 200) {
        const blob = await res.blob()
        fileDownload(blob, 'format.csv')
        toast.current.show({ severity: 'success', summary: 'Exito', detail: 'Formato generado correctamente' });

      } else {
        const { detail } = await res.json()
        toast.current.show({ severity: 'error', summary: 'Error', detail: detail });

      }

    } catch (e) {
      console.log(e)

      setLoadingForm(false)
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Ha ocurrido un error' });
    }
  }
  const getHeader = async (file,) => {
    try {


      setColumns(columnsFromBackend)
      const form = new FormData()
      const index = getValues('selectRowIndex') ?? 3
      const indexCol = getValues('selectColIndex') ?? 3
      const sheetName = getValues('sheetName')


      form.append('file_data', file)
      form.append('selectRowIndex', index)
      form.append('selectColIndex', indexCol)
      form.append('sheetName', sheetName)

      const res = await fetch(`${url}/get_header`, {
        method: 'POST',
        body: form
      })

      const body = await res.json()
      const data = body.data.map(item => {
        return {
          id: uuid4(),
          content: item,
        }
      })
      setColumns({
        ...columns,
        'list': {
          name: "list",
          title: 'LISTADO EXCEL',
          items: data
        },
      })

    } catch (e) {

      setValue('file_data', null)
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Ha ocurrido un error' });
    }

  }
  const options = [
    { value: 'tipo1', icon: 'pi pi-globe', text: 'test' },
    { value: 'tipo2', icon: 'pi pi-lock-open', text: 'text2' },
  ];

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      if (destItems.length >= 1 && destColumn.name !== "list") return;
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };


  const handleCancelFile = () => {
    setValue('file_data', null)
    setColumns(columnsFromBackend)
  }



  return (
    <>
      <h1 className='text-center font-bold text-2xl text-[#333] my-5'>Formateo  de Documento</h1>
      <div className="steps-demo">
        <Toast ref={toast}></Toast>
        <div className="card">
          <Steps model={itemsStepper} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={only} />
          <div className=' '>
            <form onSubmit={handleSubmit(onHandleSubmit)} autoComplete='off'>


              <div className={`${activeIndex === 0 ? '' : 'hidden'}`}>
                <div className=' w-[650px] flex flex-col gap-2 container mx-auto my-20'>

                  <div className='my-2'>
                    <span className="p-float-label ">
                      <Controller
                        control={control}
                        name="sheetName"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, value = '' }, fieldState
                        }) => (

                          <InputText style={{ width: '100%' }} onChange={onChange} value={value} className={classNames({ 'p-invalid': fieldState.error })} />
                        )}
                      />
                      <label htmlFor="in">Nombre de Hoja</label>
                    </span>

                    {errors.sheetName?.type === "required" && <span className="text-red-500">El campo nombre de hoja  es requerido</span>}

                  </div>
                  <div className='my-2'>
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="selectRowIndex"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, value = '' }, fieldState,
                        }) => (

                          <InputText type={'number'} style={{ width: '100%' }} onChange={onChange} value={value} className={classNames({ 'p-invalid': fieldState.error })} />
                        )}
                      />
                      <label htmlFor="in">Número de fila </label>
                    </span>

                    {errors.selectRowIndex?.type === "required" && <span className="text-red-500">El campo fila es requerido</span>}
                  </div>
                  <div className='my-2'>
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="selectColIndex"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, value = '' }, fieldState
                        }) => (

                          <InputText type={'number'} style={{ width: '100%' }} onChange={onChange} value={value} className={classNames({ 'p-invalid': fieldState.error })} />
                        )}
                      />
                      <label htmlFor="in">Número de Columna </label>
                    </span>
                    {errors.selectColIndex?.type === "required" && <span className="text-red-500">El campo columna  es requerido</span>}
                  </div>
<Controller
                    control={control}
                    name="typeDocument"
                    render={({
                      field: { onChange, value = false }, fieldState
                    }) => (
                      <div className="field-checkbox">

                        {/* <MultiStateCheckbox value={value} options={options} optionValue="value" onChange={onChange} /> */}
                        <Checkbox inputId="binary" checked={value} onChange={onChange} />
                        <label htmlFor='binary' className="p-checkbox-label text-[#66] cursor-pointer select-none"> ¿Procesar documento?</label>
                      </div>
                    )}
                  />
                  <div className='my-4'>
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="separator"
                        rules={{ required: watch('typeDocument') }}
                        render={({
                          field: { onChange, value = '' }, fieldState
                        }) => (

                          <InputText type={'text'} style={{ width: '100%' }} onChange={onChange} value={value} className={`${!watch('typeDocument') ? 'hidden' : ''} ${classNames({ 'p-invalid': fieldState.error })}`} />
                        )}
                      />
                      <label className={`${!watch('typeDocument') ? 'hidden' : ''}`} htmlFor="in">Separador Fila</label>
                    </span>
                    {errors.separator?.type === "required" && <span className={`${!watch('typeDocument') ? 'hidden' : ''} text-red-500`}>El campo separador es requerido</span>}
                  </div>
                  <div className='my-4'>
                    <span className="p-float-label">
                      <Controller
                        control={control}
                        name="separatorColumn"
                        rules={{ required: watch('typeDocument') }}
                        render={({
                          field: { onChange, value = '' }, fieldState
                        }) => (

                          <InputText type={'text'} style={{ width: '100%' }} onChange={onChange} value={value} className={`${!watch('typeDocument') ? 'hidden' : ''} ${classNames({ 'p-invalid': fieldState.error })}`} />
                        )}
                      />
                      <label className={`${!watch('typeDocument') ? 'hidden' : ''}`} htmlFor="in">Separador Columna</label>
                    </span>
                    {errors.separator?.type === "required" && <span className={`${!watch('typeDocument') ? 'hidden' : ''} text-red-500`}>El campo separador columna es requerido</span>}
                  </div>
                  
                  <div className='my-4'>
                    <span className="p-float-label">

                      <Controller
                        control={control}
                        name="fieldProcess"
                        rules={{ required: watch('typeDocument') }}
                        render={({
                          field: { onChange, value = '' }, fieldState
                        }) => (

                          <InputText type={'text'} style={{ width: '100%' }} onChange={onChange} value={value} className={`${!watch('typeDocument') ? 'hidden' : ''} ${classNames({ 'p-invalid': fieldState.error })}`} />
                        )}
                      />
                      <label className={`${!watch('typeDocument') ? 'hidden' : ''}`} htmlFor="in">Nombre Columna con la que se trabajá</label>
                    </span>
                    {errors.fieldProcess?.type === "required" && <span className={`${!watch('typeDocument') ? 'hidden' : ''} text-red-500`}>El campo nombre columna es requerido</span>}
                  </div>
                  <div className='flex items-center w-full justify-center'>
                    <Button label="Siguiente" iconPos='right' icon="pi pi-arrow-right" type='button' onClick={() => handleNext(1)} className="p-button-secondary" />
                  </div>
                </div>
              </div>

              <div className={`${activeIndex === 1 ? '' : 'hidden'}`}>
                <div className=' w-full flex flex-col gap-2 container mx-auto my-20'>
                

                  <div className='flex items-center'>

                    <input type="file" {...register('file_data', {
                      required: true,
                      onChange: async (e) => {
                        const file = e.currentTarget.files[0]
                        const result = await trigger()
                        if (!result) {
                          setValue('file_data', null)

                          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Existen campos sin rellenar' });
                          return
                        }
                        await getHeader(file)

                      }

                    })} className=' my-5  text-sm text-slate-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100'/>
                    <Button icon='pi pi-fw pi-times' onClick={handleCancelFile} className="custom-cancel-btn p-button-danger p-button-rounded p-button-outlined" />
                    <Tooltip target=".custom-cancel-btn" content="Limpiar" position="bottom" />
                  </div>



                  <div style={{ display: "grid", alignContent: 'center', justifyContent: 'center', gridTemplateColumns: 'repeat(auto-fit,minmax(200px,1fr))', height: "100%", }}>
                    <DragDropContext
                      onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                    >
                      {Object.entries(columns).map(([columnId, column], index) => {
                        return (
                          <div
                            style={{
                              // display: "flex",
                              // flexDirection: "column",
                              // alignItems: "center",
                              gridColumn: columnId === 'list' ? '1/-1' : '',
                            }}
                            key={columnId}
                          >
                            <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>{column.title}</h2>
                            <div style={{ margin: 8 }}>
                              <Droppable droppableId={columnId} key={columnId} direction={`${columnId === 'list' ? 'horizontal' : 'vertical'}`}>
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={{
                                        background: snapshot.isDraggingOver
                                          ? "lightblue"
                                          // : "lightgrey",
                                          : (columnId === 'list' ? '#333' : 'lightgrey'),
                                        height: columnId !== 'list' ? 100 : 112,
                                        overflowX: columnId !== 'list' ? 'initial' : 'auto',
                                        padding: 4,
                                        minWidth: 200,
                                        // width: '100%',
                                        display: columnId !== 'list' ? 'block' : 'flex',
                                        gap: columnId !== 'list' ? '0' : '1rem',
                                        flexDirection: columnId !== 'list' ? 'column' : 'row',

                                        // display:'grid',

                                        //minHeight: 500
                                      }}
                                    >
                                      {column.items.map((item, index) => {
                                        return (
                                          <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                          >
                                            {(provided, snapshot) => {
                                              return (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={{
                                                    userSelect: "none",
                                                    padding: 16,
                                                    margin: "0 0 8px 0",
                                                    height: 80,
                                                    minHeight: "50px",
                                                    // flexGrow: columnId !== 'list' ? 0 : 3,
                                                    flexShrink: columnId !== 'list' ? 1 : 0,
                                                    width: 195,
                                                    backgroundColor: snapshot.isDragging
                                                      ? "#263B4A"
                                                      : "#456C86",
                                                    color: "white",
                                                    ...provided.draggableProps.style
                                                  }}
                                                >
                                                  {item.content}
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                      {provided.placeholder}
                                    </div>
                                  );
                                }}
                              </Droppable>
                            </div>
                          </div>
                        );
                      })}
                    </DragDropContext>
                    {/* <button onClick={onGetData}>get Data</button> */}
                  </div>

                  {/* BUTTON */}
                  <div className='my-5 flex justify-center items-center gap-2'>
                    {/* <button type='submit' className='bg-blue-500 text-white p-2 rounded-md  font-bold'>Formatear Documento</button> */}
                    <Button label="Atras" iconPos='left' icon="pi pi-arrow-left" type='button' onClick={() => handleNext(0)} className="p-button-secondary" />
                    <Button label="Formatear Documento" type='submit' className='p-button-info' loading={loadingForm} />
                  </div>
                </div>
              </div>
              <div className={`${activeIndex === 2 ? '' : 'hidden'}`}>

              </div>

            </form>
          </div>
        </div>
      </div>
      {/* <FormComponent /> */}
    </>
  );
}

export default App;
